<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("support.contactPersons") }}
        </h3>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div
          v-for="(contactPerson, index) in contactPersons"
          :key="index"
          class="col"
        >
          <div class="card" style="border: none">
            <div class="card-body">
              <div class="row">
                <div class="col" style="text-align: center">
                  <img
                    class="img img-fluid"
                    :src="contactPerson.image"
                    style="max-width: 250px; border-radius: 50%"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col py-1" style="text-align: center">
                  <span class="text-h6">
                    {{ contactPerson.name }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col py-0" style="text-align: center">
                  <span class="text-subtitle-1">
                    {{ contactPerson.position }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col" style="text-align: center">
                  <button
                    class="btn btn-primary"
                    @click="routeToSupport(contactPerson.name)"
                  >
                    {{ $t("support.contact") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      contactPersons: [
        {
          name: "Philipp Niehues",
          image: "/media/contactPersons/PN.jpg",
          position: "Geschäftsführung"
        },
        {
          name: "Jens Niehues",
          image: "/media/contactPersons/JN.jpg",
          position: "Technische Leitung"
        },
        {
          name: "Johannes Vallo",
          image: "/media/contactPersons/JV.jpg",
          position: "Projektleitung"
        }
      ]
    };
  },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.helpContactPerson"),
          route: "/help/support"
        }
      ]);
    },
    routeToSupport(to) {
      this.$router.push({ name: "helpSupport", params: { to: to } });
    }
  }
};
</script>

<style scoped></style>
